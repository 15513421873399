import React from 'react';

const ViewForm = (props) => {

  return (
    <>
      <div className="modal fade" id="viewForm" >
        <div className="modal-dialog modal-xl" >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-align-center">View Applicant Form<button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
              </button></h5>

            </div>
            <div className="modal-body text-align-center" >
              <p className='container'>
                {/* {JSON.stringify(props?.applicationDetails,null,2)} */}
                <div className="panel setup-content">

                  <div className="panel-body view-form">
                    <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                      <label className="control-label label-english">Name of Student:</label><span className="mandatory">*</span><label className="new-urdu">طالب علم کا نام</label>
                      <input
                        maxLength={200}
                        type="text"
                        placeholder="Name of Student"
                        className="form-control input-label-style"
                        id="name"
                        name="name"
                        value={props.applicationDetails?.name}


                      />
                    </div>
                    <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                      <label className="control-label label-english">Father Name:</label><span className="mandatory">*</span><label className="new-urdu">والد کا نام</label>
                      <input
                        maxLength={200}
                        type="text"
                        placeholder="Name of Student"
                        className="form-control input-label-style"
                        id="name"
                        name="name"
                        value={props.applicationDetails?.father_name}


                      />
                    </div>

                    <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                      <label className="control-label label-english">Form-B/CNIC:</label><span className="mandatory">*</span><label className="new-urdu">فارم ب/ شناختی کارڈ نمبر</label>
                      <input
                        maxLength={13}
                        type="text"
                        placeholder="6110112345678 (without dashes)"

                        className="form-control input-label-style"
                        id="cnic"
                        name="cnic"
                        value={props.applicationDetails?.cnic}
                      />
                    </div>
                    <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                      <label className="control-label label-english">Father/Guardian CNIC:</label><span className="mandatory">*</span><label className="new-urdu">والد/سرپرست شناختی کارڈ نمبر</label>
                      <input
                        maxLength={13}
                        type="text"
                        placeholder="6110112345678 (without dashes)"

                        className="form-control input-label-style"
                        id="cnic"
                        name="cnic"
                        value={props.applicationDetails?.father_cnic}
                      />
                    </div>
                    <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                      {props.applicationDetails?.form_b ? (
                        <div>
                          <label className="control-label label-english">Form-B Picture:</label>
                          <span className="mandatory">*</span>
                          <label className="new-urdu">فارم ب کی تصویر</label>
                        </div>
                      ) : null}
                      <br />
                      <div className="col-xs-12 col-sm-12">
                        {props.applicationDetails?.form_b ? (
                          <img
                            src={props.applicationDetails.form_b}
                            className="img-rounded picture-size"
                            alt="Form-B Picture"
                            style={{ width: "200px", height: "200px" }}
                          />
                        ) : (
                          null
                        )}
                      </div>
                      {/* </div> */}
                      {/*                            
                            <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                                <label className="control-label label-english">Form-B Picture:</label><span className="mandatory">*</span><label className="new-urdu">فارم ب کی تصویر</label>
                                <br />
                                <div className="col-xs-12 col-sm-12">
                                <img src={props.applicationDetails?.form_b} class="img-thumbnail" alt="Responsive image" width="200" height="200"></img>
                                
                                </div>  */}
                    </div>
                    <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                      {props.applicationDetails?.photo ? (
                        <div>
                          <label className="control-label label-english">Student Picture:</label>
                          <span className="mandatory">*</span>
                          <label className="new-urdu"> طالب علم کی تصویر</label>
                        </div>
                      ) : (
                        null
                      )}
                      <br />


                      <div className="col-xs-12 col-sm-12">
                        {props.applicationDetails?.photo ? (
                          <img
                            src={props.applicationDetails.photo}
                            className="img-thumbnail picture-size"
                            alt="Form-B Picture"
                            style={{ width: "200px", height: "200px" }}
                          />
                        ) : (
                         null
                        )}

                      </div>


                    </div>
                  </div>
                </div>
              </p>

              <br />
            </div></div></div>


      </div>

    </>)
}

export default ViewForm