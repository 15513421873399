import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import PrintSlip from './PrintSlip';
import ViewForm from './ViewForm';

const Search = () => {
  const [searchBox, setSearchBox] = useState('');
  const [receivedData, setReceivedData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  let history = useNavigate();
  const refClose = useRef(null);
  const voucherOpen = useRef(null);
  const viewForm = useRef(null);

  const [applicationDetails, setApplicationDetails] = useState(null);
  const handleVoucher = (pro) => {
    setApplicationDetails(pro);
    voucherOpen.current.click();


  }
  const handleVoucher2 = (pro) => {
    setApplicationDetails(pro);
    viewForm.current.click();


  }

  const onChange = (e) => {
    setSearchBox(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setReceivedData([]);
    if (searchBox.length !== 13 || isNaN(searchBox)) {

      toastr.error("CNIC must be 13-digits long");
      document.querySelector("#searchBox").classList.remove("is-valid");
      document.querySelector("#searchBox").classList.add("is-invalid");
      document.querySelector("#searchBox").focus();

      return;
    }




    const params = {
      cnic: searchBox
      // Add more parameters as needed
    };
    const queryParams = new URLSearchParams(params).toString();

    try {
      const response = await fetch(
        `https://etea.online/apply/api/getCandidateInfobyCNIC?${queryParams}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const json = await response.json();

      if (json.Status) {
        if (json.Message.length === 0) {
          toastr.error(`No record exists in the Database, please check your CNIC`);
        }
        else {
          setShowTable(true);
          setReceivedData(json.Message);
        }
        // toastr.success("Record Inserted");   
        // setStep4(true); 
        // setTrackingNumber(json.Message);
        // setStep5(false);

      } else {
        toastr.error(json.Message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toastr.error('Error fetching data. Please try again later.');
      // Handle other error scenarios or display an error message to the user
    }



  };
  return (
    <div className="container form-xs">
      {/* {JSON.stringify(receivedData)} */}
      <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginBottom: 15 }}>
        <div>

          <h6 className="page-heading">Application Form for Scholarship/Admission in Class 7th in Centers of Excellence</h6>
          <p className="page-sub-heading">Under the scheme provision of Free &amp; Quality Education for Talented Students of Government Schools of Khyber Pakhtunkhwa</p>
        </div>
      </div>

      <form id="myForm" onSubmit={handleSubmit}>
        <div className="z-depth-1 white col s12 l9 m8 offset-s0 offset-l2 offset-m3 form-container">
          <div className="panel-body">
            {/* {JSON.stringify(receivedData, null, 2)} */}
            <div className="form-group col-xs-12 col-sm-9 col-md-6 padding-xs-0">
              <label className="control-label label-english">Form-B/ CNIC:</label><span className="mandatory">*</span><label className="control-label new-urdu">فارم ب/ شناختی کارڈ نمبر</label>
              <input pattern="[0-9]*" inputmode="numeric" maxLength={13} onChange={onChange} type="text" name='searchBox' id='searchBox' placeholder={'Enter 13-Digit CNIC No'} className="form-control input-label-style" />
            </div>
            <div className="col-xs-12 col-sm-3 search-btn">

              {<input className="btn btn-primary nextBtn btn-style btn-large" type="submit" value="Search" />}

            </div>

            {receivedData?.length === 0 ? (<p></p>) : (<div className="col-xs-12 table-status" style={{ border: '1px solid #dddddd' }}>

              <div className='table-header'>
                <div className='hidden-xs col-sm-1 p-l-r-0' >Sr.No</div>
                <div className='hidden-xs col-sm-2 p-l-r-0' >Father CNIC</div>
                <div className='hidden-xs col-sm-2 p-l-r-0' >Student CNIC</div>
                <div className='hidden-xs col-sm-2 p-l-r-0' >Student Name</div>
                <div className='hidden-xs col-sm-4 p-l-r-0' >Status</div>
                <div className='hidden-xs col-sm-1 p-l-r-0' >View / Print</div>
              </div>

              {Array.isArray(receivedData) && receivedData?.map((data, index) => (
                // {receivedData?.map((data, index) => (
                <div className='table-body' key={index}>
                  <div className='hidden-sm hidden-md hidden-lg heading-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>Sr.No:</div>
                    <div className='col-xs-6 text-right-xs' scope="row">{index + 1}</div>
                  </div>

                  <div className='hidden-sm hidden-md hidden-lg body-content-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>Father CNIC:</div>
                    <div className='col-xs-6 text-right-xs' scope="row">{data.father_cnic}</div></div>

                  <div className='hidden-sm hidden-md hidden-lg body-content-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>Student CNIC:</div>
                    <div className='col-xs-6 text-right-xs' scope="row">{data.cnic}</div></div>

                  <div className='hidden-sm hidden-md hidden-lg body-content-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>Student Name:</div>
                    <div className='col-xs-6 text-right-xs' scope="row">{data.name}</div></div>

                  <div className='hidden-sm hidden-md hidden-lg body-content-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>Status:</div>
                    <div className='col-xs-6 text-right-xs' scope="row">
                      <span className='col-style-xs'>Application &#9989;</span>
                      <span className='col-style-xs'
                        dangerouslySetInnerHTML={{
                          __html:
                            data.photo === ""
                              ? "Picture &#10060;"
                              : "Picture &#9989;",
                        }}
                      />
                      <span className='col-style-xs'
                        dangerouslySetInnerHTML={{
                          __html:
                            data.photo === ""
                              ? "Form-B &#10060;"
                              : "Form-B &#9989;",
                        }}
                      />

                      
                      </div>
                      <span className='col-style-xs' style={{ paddingRight: 10, paddingLeft: 10, borderRight: '1px solid #333333', }}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.payment_status === 0
                          
                            ? "Payment &#10060;"
                            : "Payment &#9989;",
                      }}
                      />
                  </div>

                  <div className='hidden-sm hidden-md hidden-lg body-content-xs col-xs-12 padding-xs-0'>
                    <div className='col-xs-6 text-left-xs'>View/Print:</div>

                    {
                      data.payment_status === 0 && data.photo !== "" &&
                      <div className='col-xs-6 text-right-xs'>
                        <a type="button" className="btn-print" onClick={() => { handleVoucher(data) }}>Print Now</a>
                      </div>
                    }
                    {!(data.payment_status === 0 && data.photo !== "") &&
                      <div className='col-xs-6 col-sm-1 p-l-r-0 hidden-xs'>
                        <a type="button" className="btn-generate-voucher" onClick={() => { }}>View Form</a>
                      </div>
                    }

                  </div>









                  <div className='col-xs-6 col-sm-1 p-l-r-0 hidden-xs first-column' scope="row">{index + 1}</div>
                  <div className='col-xs-6 col-sm-2 p-l-r-0 hidden-xs'>{data.father_cnic}</div>
                  <div className='col-xs-6 col-sm-2 p-l-r-0 hidden-xs'>{data.cnic}</div>
                  <div className='col-xs-6 col-sm-2 p-l-r-0 hidden-xs'>{data.name}</div>
                  <div className='col-xs-6 col-sm-4 p-l-r-0 hidden-xs'>
                    <span className='col-style-xs' style={{ paddingRight: 10, paddingLeft: 10, borderRight: '1px solid #333333' }}>Application &#9989;</span>
                    <span className='col-style-xs' style={{ paddingRight: 10, paddingLeft: 10, borderRight: '1px solid #333333', }}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.photo === ""
                            ? "Picture &#10060;"
                            : "Picture &#9989;",
                      }}
                    />
                    <span className='col-style-xs' style={{ paddingRight: 10, paddingLeft: 10, borderRight: '1px solid #333333', }}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.photo === ""
                            ? "Form-B &#10060;"
                            : "Form-B &#9989;",
                      }}
                    />
                    <span className='col-style-xs' style={{ paddingRight: 10, paddingLeft: 10, borderRight: '1px solid #333333', }}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.payment_status === 0
                          
                            ? "Payment &#10060;"
                            : "Payment &#9989;",
                      }}
                    />
                  </div>
                  {data.payment_status === 0 && data.photo !== "" &&
                    <div className='col-xs-6 col-sm-1 p-l-r-0 hidden-xs'>
                      <a type="button" className="btn-print" onClick={() => { handleVoucher(data) }}>Print Now</a></div>}
                  {!(data.payment_status === 0 && data.photo !== "") &&
                    <div className='col-xs-6 col-sm-1 p-l-r-0 hidden-xs'>
                      <a type="button" className="btn-generate-voucher" onClick={() => { handleVoucher2(data) }}>View</a>
                    </div>
                  }
                </div>
              ))}

            </div>)}
          </div>
        </div>
      </form>



      <button style={{ display: 'none' }} ref={voucherOpen} type="button" className="btn btn-warning hide" data-toggle="modal" data-target="#pdfhandler">Print Now</button>
  
      <button style={{ display: 'none' }} ref={viewForm} type="button" className="btn btn-warning hide" data-toggle="modal" data-target="#viewForm"></button>
    <PrintSlip applicationDetails={applicationDetails} />
    <ViewForm applicationDetails={applicationDetails} />



      <div>


        <div>

        </div>


      </div>


      {/* <PrintSlip /> */}
      <br /><br /><br /><br /></div>



  )
}

export default Search