import React from 'react';
import GenerateVoucher from "./GenerateVoucher";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";


const PrintSlip = (props) => {

  return (
    <>
      <div className="modal fade" id="pdfhandler" >
        <div className="modal-dialog modal-xl" >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Applicant Voucher   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
              </button></h4>

            </div>
            <div className="modal-body text-align-center" >
              <p className='container'>
                {/* Record of {JSON.stringify(props?.applicationDetails,null,2)} */}
              </p>
              <PDFDownloadLink document={<GenerateVoucher applicant={props?.applicationDetails} />} filename="ETEA-Apply-DepositSlip.pdf">
                {({ loading }) => (loading ? <button>Generating PDF . . .</button> : <button className="btn btn-success">Download PDF</button>)}
              </PDFDownloadLink>
              <br />
              <div style={{ marginTop: '10px', width: '100%', height: '1200px' }}> {/* Adjust width and height */}

                <PDFViewer style={{ width: '100%', height: '100%' }}>
                  <GenerateVoucher applicant={props?.applicationDetails} />
                </PDFViewer>
              </div></div></div></div>


      </div>

    </>)
}

export default PrintSlip