import React, { useState } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link } from "react-router-dom";


const Page3 = (props) => {
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(true);
  // Testing code here...
  // const schools = [
  //   'University Public School Peshawar',
  //   'Islamia Collegiate School Pesahwar',
  //   'Peshawar Public School Peshawar(Boys & Girls)',
  //   'Fazal Haq College Mardan',
  //   'Akram Khan Durrani College Bannu',
  //   'Mufti Mehmood School and College D.I. Khan',
  //   'Abboattabad Public School Abboattabad',
  //   'Univeristy Wensom School and College DIK',
  //   'Agriculture Univerit School and College',
  //   'Excelsior School & College Swat (Boys & Girls)',
  //   'University Model Schol for Girls Pesahwar'


  // ];
  const schools = [
    'University Public School Peshawar',
    'Islamia Collegiate School Peshawar',
    'Peshawar Public School Peshawar (Boys + Girls)',
    'Fazal Haq College Mardan',
    'Akram Khan Durrani College Bannu',
    'Mufti Mehmood School and College D.I. Khan',
    'Abbottabad Public School Abbottabad',
    'University Wensam School and College D.I. Khan',
    'Agriculture University Public School and College',
    'Exceisior School & College Swat (B&G)',
    'University Model School for Girls Peshawar'
  ];
  const convertToUrduNumber = (num) => {
    const urduNumbers = ['پہلی', 'دوسری', 'تیسری', 'چوتھی', 'پانچوی'];
    return urduNumbers[num - 1]; // Adjust the index as needed
  };

  const [preferences, setPreferences] = useState({
    pref1: 'Select',
    pref2: 'Select',
    pref3: 'Select',
    pref4: 'Select',
    pref5: 'Select'
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    const newPreferences = { ...preferences, [name]: value };

    // Update subsequent preferences, removing the selected value
    for (let i = parseInt(name.substr(4)) + 1; i <= 5; i++) {
      const prefName = `pref${i}`;
      newPreferences[prefName] = value === newPreferences[prefName] ? 'Select' : newPreferences[prefName];
    }

    setPreferences(newPreferences);
    props.setApplicant({ ...props.applicant, [name]: value });
  };
  const selectOptions = (prefNum) => {
    const selectedValue = preferences[`pref${prefNum}`];
    const filteredOptions = schools
      .filter(school => school !== selectedValue && !Object.values(preferences).includes(school))
      .map((school, index) => (
        <option key={index} value={school}>{school}</option>
      ));

    return selectedValue ? [
      <option key={selectedValue} value={selectedValue}>{selectedValue}</option>,
      ...filteredOptions
    ] : ['Select', ...filteredOptions];
  };

  // End.. testing code here...  

  const [trackingNumber, setTrackingNumber] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.applicant.pref1.length < 3) {

      toastr.error("Preference 1 must be selected");
      document.querySelector("#pref1").classList.remove("is-valid");
      document.querySelector("#pref1").classList.add("is-invalid");
      document.querySelector("#pref1").focus();

      return;
    }
    else if (props.applicant.pref2.length < 3) {
      document.querySelector("#pref1").classList.remove("is-invalid");
      document.querySelector("#pref1").classList.add("is-valid");

      toastr.error("Preference 2 must be provided");

      document.querySelector("#pref2").classList.remove("is-valid");
      document.querySelector("#pref2").classList.add("is-invalid");
      document.querySelector("#pref2").focus();

      return;
    }
    else if (props.applicant.pref3.length < 3) {
      document.querySelector("#pref2").classList.remove("is-invalid");
      document.querySelector("#pref2").classList.add("is-valid");

      toastr.error("Preference 3 must be provided");

      document.querySelector("#pref3").classList.remove("is-valid");
      document.querySelector("#pref3").classList.add("is-invalid");
      document.querySelector("#pref3").focus();

      return;
    }
    else if (props.applicant.pref4.length < 3) {
      document.querySelector("#pref3").classList.remove("is-invalid");
      document.querySelector("#pref3").classList.add("is-valid");

      toastr.error("Preference 4 must be provided");

      document.querySelector("#pref4").classList.remove("is-valid");
      document.querySelector("#pref4").classList.add("is-invalid");
      document.querySelector("#pref4").focus();

      return;
    }
    else if (props.applicant.pref5.length < 3) {
      document.querySelector("#pref4").classList.remove("is-invalid");
      document.querySelector("#pref4").classList.add("is-valid");

      toastr.error("Preference 5 must be provided");

      document.querySelector("#pref5").classList.remove("is-valid");
      document.querySelector("#pref5").classList.add("is-invalid");
      document.querySelector("#pref5").focus();

      return;
    }
    // console.error(JSON.stringify(props.applicant));

    try {
      const response = await fetch(
        'https://etea.online/apply/api/saveCandidateInfo',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(props.applicant),
          // mode: 'no-cors',

          
        }

      );
      console.log('Response:', response); // Log the raw response

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const json = await response.json();

      if (json.Status) {
        // toastr.success("Record Inserted");   
        setStep4(true);
        setTrackingNumber(json.Message);
        setStep5(false);
        // console.log(json.data);
      } else {
        toastr.error(`The following errors have occurred: ${json.Message}`);
        // toastr.error('It seems that your record already exists in the Database.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toastr.error('Error fetching data. Please try again later.'+error);
      // Handle other error scenarios or display an error message to the user
    }


  };
  return (

    <div className="container form-xs">
      <div className="col-xs-12 col-sm-12 col-md-12 hidden-xs" style={{ marginBottom: 15 }}>
        <div>
          <h6 className="page-heading">Application Form for Scholarship/Admission in Class 7th in Centers of Excellence</h6>
          <p className="page-sub-heading">Under the scheme provision of Free &amp; Quality Education for Talented Students of Government Schools of Khyber Pakhtunkhwa</p>
        </div>
      </div>
      <div className="stepwizard hidden-sm hidden-md hidden-lg">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-4">
            <a href="#step-1" type="button"
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 1</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-2" onClick={handleSubmit} type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 2</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-3" type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 3</a>

          </div>
        </div>
      </div>



      <div className="stepwizard hidden-xs">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-1" type="button"
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>1</a>
            <p><small>Personal Information</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-2" onClick={() => { props.setStep3(false); props.setStep2(true) }} type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>2</a>
            <p><small>Name of Institution/School</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-3" type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>3</a>
            <p><small>Desired Institution in order of Preference</small></p>
          </div>
        </div>
      </div>
      {step4 &&
        <div className="z-depth-1 white col-xs-12 s12 l9 m8 offset-s0 offset-l2 offset-m3 form-container">
          <h5 className="page-heading color-green">Application Submitted Successfully <img src="./tick-icon.png" className='tick-icon' title='Application Submitted Successfully'></img></h5>

          <div>
            <p className='last-step-paragraph'>Your form has been submitted. You have completed three stages. In the next two stages, you will need to upload your image and a copy of Form B. Click on the link below to download the ETEA Mobile Application, where you have completed the next steps.</p>
            <p className='new-urdu last-step-info'> آپ کا فارم جمع ہو گیا ہے۔ آپ نے تین مراحل پورے کر لیے ہیں۔ آپ کو مزید دو مراحل میں اپنی تصویر اور فارم۔ب کی کاپی اپلوڈ کرنی ہو گی۔ آپ مندرجہ زیل لنک پر کلک کریں جس سے آپ ایٹا کی موبائل ایپلیکیشن ڈاون لوڈ ہو جائے گی جہاں پر آپ نے اگلے مراحل پورے کرنے ہیں۔  آپ کا ٹریکنگ آئی ڈی مندرجہ زیل ہے۔</p>

            <h5 className="page-heading">Tracking ID: {trackingNumber}</h5>
            <div className="col-xs-12 col-sm-12 p-l-r-0 hidden-xs text-align-center">
              <a className='new-urdu download-app-link' href='https://play.google.com/store/search?q=etea&c=apps&hl=en&gl=US&pli=1'>موبائل ایپلیکیشن ڈاون لوڈ کرنے کے لیے یہاں کلک کریں۔ </a>
              <br /><br />

    

              <Link to="/" className="btn btn-primary" >Go to Main Page</Link>
              <br /><br />
            </div>



          </div>

        </div>

      }

      {step5 && <form id="form3" onSubmit={handleSubmit}>
        <div className="z-depth-1 white col s12 l9 m8 offset-s0 offset-l2 offset-m3 form-container">

          <div className="panel setup-content" id="step-3">
            <h5 className="page-heading">Desired Institution in order of Preference</h5>
            <div className="panel-body">

              <div>
                {[1, 2, 3, 4, 5].map((num) => (
                  <div key={num} className="form-group col-xs-12 col-sm-6 padding-xs-0">
                    <label className="control-label label-english">Preference {num}:</label>
                    <span className="mandatory">*</span>
                    {/* <label className="new-urdu">ترجیح {num}</label> */}
                    <label className="new-urdu">{convertToUrduNumber(num)} ترجیح</label>

                    <select
                      id={`pref${num}`}
                      name={`pref${num}`}
                      value={preferences[`pref${num}`]}
                      onChange={onChange}
                      className="form-control"
                      placeholder={`Select Preference ${num}`}
                    >
                      {selectOptions(num)}
                    </select>
                  </div>
                ))}
              </div>
              {/* <div className="form-group col-xs-6">
            <label className="control-label label-english">Preference 1:</label><span className="mandatory">*</span><label className="new-urdu">پہلی ترجیح</label>
            <select id="pref1" name="pref1" value={props.applicant.pref1} onChange={onChange} className="form-control"  placehoder="Select Preference 1">
              <option value>Select</option>
              <option value="Abbottabad Public School Abbottabad">Abbottabad Public School Abbottabad</option>
              <option value="Bannu Model School & College Bannu">Bannu Model School &amp; College Bannu</option>
              <option value="Fazle Haq College Mardan (Boys & Girls)">Fazle Haq College Mardan (Boys &amp; Girls)</option>
              <option value="Islamia Collegiate School Peshawar">Islamia Collegiate School Peshawar</option>
            </select>
          </div>
          <div className="form-group col-xs-6">
            <label className="control-label label-english">Preference 2:</label><span className="mandatory">*</span><label className="new-urdu">دوسری ترجیح</label>
            <select id="pref2" name="pref2" value={props.applicant.pref2} onChange={onChange} className="form-control"  placehoder="Select Preference 2">
              <option value>Select</option>
              <option value="Abbottabad Public School Abbottabad">Abbottabad Public School Abbottabad</option>
              <option value="Bannu Model School & College Bannu">Bannu Model School &amp; College Bannu</option>
              <option value="Fazle Haq College Mardan (Boys & Girls)">Fazle Haq College Mardan (Boys &amp; Girls)</option>
              <option value="Islamia Collegiate School Peshawar">Islamia Collegiate School Peshawar</option>
            </select>
          </div>
          <div className="form-group col-xs-6">
            <label className="control-label label-english">Preference 3:</label><span className="mandatory">*</span><label className="new-urdu">تیسری ترجیح</label>
            <select id="pref3" name="pref3" value={props.applicant.pref3} onChange={onChange} className="form-control"  placehoder="Select Preference 3">
              <option value>Select</option>
              <option value="Abbottabad Public School Abbottabad">Abbottabad Public School Abbottabad</option>
              <option value="Bannu Model School & College Bannu">Bannu Model School &amp; College Bannu</option>
              <option value="Fazle Haq College Mardan (Boys & Girls)">Fazle Haq College Mardan (Boys &amp; Girls)</option>
              <option value="Islamia Collegiate School Peshawar">Islamia Collegiate School Peshawar</option>
            </select>
          </div>
          <div className="form-group col-xs-6">
            <label className="control-label label-english">Preference 4:</label><span className="mandatory">*</span><label className="new-urdu">چوتھی ترجیح</label>
            <select id="pref4" name="pref4" value={props.applicant.pref4} onChange={onChange} className="form-control"  placehoder="Select Preference 4">
              <option value>Select</option>
              <option value="Abbottabad Public School Abbottabad">Abbottabad Public School Abbottabad</option>
              <option value="Bannu Model School & College Bannu">Bannu Model School &amp; College Bannu</option>
              <option value="Fazle Haq College Mardan (Boys & Girls)">Fazle Haq College Mardan (Boys &amp; Girls)</option>
              <option value="Islamia Collegiate School Peshawar">Islamia Collegiate School Peshawar</option>
            </select>
          </div>
          <div className="form-group col-xs-6">
            <label className="control-label label-english">Preference 5:</label><span className="mandatory">*</span><label className="new-urdu">پانچوی ترجیح</label>
            <select id="pref5" name="pref5" value={props.applicant.pref5} onChange={onChange} className="form-control"  placehoder="Select Preference 5">
              <option value>Select</option>
              <option value="Abbottabad Public School Abbottabad">Abbottabad Public School Abbottabad</option>
              <option value="Bannu Model School & College Bannu">Bannu Model School &amp; College Bannu</option>
              <option value="Fazle Haq College Mardan (Boys & Girls)">Fazle Haq College Mardan (Boys &amp; Girls)</option>
              <option value="Islamia Collegiate School Peshawar">Islamia Collegiate School Peshawar</option>
            </select>
          </div> */}
              <div className="form-group col-xs-12 text-align-center">
                <button className="btn btn-default prevBtn btn-style btn-large" onClick={() => { props.setStep3(false); props.setStep2(true) }} type="button">Previous</button>
                <input type="submit" className="btn btn-success nextBtn btn-style btn-large" value="Save & Submit" name="Submit Form" id="finish" />

              </div>
            </div>
          </div> </div>
      </form>}


    </div>
  )
}

export default Page3