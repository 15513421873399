import React from 'react'
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link, useNavigate } from "react-router-dom";

const Page2 = (props) => {
  let classOptions = [];
  if (props.applicant.gender === 'Boy') {
    classOptions = [
      <option key="" value="">Select</option>,
      <option key="gps" value="gps">Government Primary School</option>,
      <option key="ghs" value="ghs">Government High School</option>,
      <option key="gcs" value="gcs">Government Community School</option>,
      <option key="gms" value="gms">Government Middle School</option>
    ];
  } else {
    classOptions = [
      <option key="" value="">Select</option>,
      <option key="ggps" value="ggps">Government Primary School</option>,
      <option key="gghs" value="gghs">Government High School</option>,
      <option key="ggcs" value="ggcs">Government Community School</option>,
      <option key="ggms" value="ggms">Government Middle School</option>
      // Add other options for the 'Girl' gender if needed
    ];
  }

  const onChange = (e) => {
    props.setApplicant({ ...props.applicant, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.applicant.class1.length < 3) {

      toastr.error("Class 1 must be selected");
      document.querySelector("#class1").classList.remove("is-valid");
      document.querySelector("#class1").classList.add("is-invalid");
      document.querySelector("#class1").focus();

      return;
    }
    else if (props.applicant.class1address.length < 3) {
      document.querySelector("#class1").classList.remove("is-invalid");
      document.querySelector("#class1").classList.add("is-valid");

      toastr.error("School Address must be provided");

      document.querySelector("#class1address").classList.remove("is-valid");
      document.querySelector("#class1address").classList.add("is-invalid");
      document.querySelector("#class1address").focus();

      return;
    }
    else if (props.applicant.class2.length < 3) {
      document.querySelector("#class1address").classList.remove("is-invalid");
      document.querySelector("#class1address").classList.add("is-valid");

      toastr.error("Class 2 must be Selected");

      document.querySelector("#class2").classList.remove("is-valid");
      document.querySelector("#class2").classList.add("is-invalid");
      document.querySelector("#class2").focus();

      return;
    }
    else if (props.applicant.class2address.length < 3) {
      document.querySelector("#class2").classList.remove("is-invalid");
      document.querySelector("#class2").classList.add("is-valid");

      toastr.error("School Address must be provided");

      document.querySelector("#class2address").classList.remove("is-valid");
      document.querySelector("#class2address").classList.add("is-invalid");
      document.querySelector("#class2address").focus();

      return;
    }
    else if (props.applicant.class3.length < 3) {
      document.querySelector("#class2address").classList.remove("is-invalid");
      document.querySelector("#class2address").classList.add("is-valid");

      toastr.error("Class 3 must be Selected");

      document.querySelector("#class3").classList.remove("is-valid");
      document.querySelector("#class3").classList.add("is-invalid");
      document.querySelector("#class3").focus();

      return;
    }
    else if (props.applicant.class3address.length < 3) {
      document.querySelector("#class3").classList.remove("is-invalid");
      document.querySelector("#class3").classList.add("is-valid");

      toastr.error("School Address must be provided");

      document.querySelector("#class3address").classList.remove("is-valid");
      document.querySelector("#class3address").classList.add("is-invalid");
      document.querySelector("#class3address").focus();

      return;
    }
    else if (props.applicant.class4.length < 3) {
      document.querySelector("#class3address").classList.remove("is-invalid");
      document.querySelector("#class3address").classList.add("is-valid");

      toastr.error("Class 4 must be Selected");

      document.querySelector("#class4").classList.remove("is-valid");
      document.querySelector("#class4").classList.add("is-invalid");
      document.querySelector("#class4").focus();

      return;
    }
    else if (props.applicant.class4address.length < 3) {
      document.querySelector("#class4").classList.remove("is-invalid");
      document.querySelector("#class4").classList.add("is-valid");

      toastr.error("School Address must be provided");

      document.querySelector("#class4address").classList.remove("is-valid");
      document.querySelector("#class4address").classList.add("is-invalid");
      document.querySelector("#class4address").focus();

      return;
    }
    else if (props.applicant.class5.length < 3) {
      document.querySelector("#class4address").classList.remove("is-invalid");
      document.querySelector("#class4address").classList.add("is-valid");

      toastr.error("Class 5 must be Selected");

      document.querySelector("#class5").classList.remove("is-valid");
      document.querySelector("#class5").classList.add("is-invalid");
      document.querySelector("#class5").focus();

      return;
    }
    else if (props.applicant.class5address.length < 3) {
      document.querySelector("#class5").classList.remove("is-invalid");
      document.querySelector("#class5").classList.add("is-valid");

      toastr.error("School Address must be provided");

      document.querySelector("#class5address").classList.remove("is-valid");
      document.querySelector("#class5address").classList.add("is-invalid");
      document.querySelector("#class5address").focus();

      return;
    }
    else if (props.applicant.class6.length < 3) {
      document.querySelector("#class5address").classList.remove("is-invalid");
      document.querySelector("#class5address").classList.add("is-valid");

      toastr.error("Class 6 must be Selected");

      document.querySelector("#class6").classList.remove("is-valid");
      document.querySelector("#class6").classList.add("is-invalid");
      document.querySelector("#class6").focus();

      return;
    }
    else if (props.applicant.class6address.length < 3) {
      document.querySelector("#class6").classList.remove("is-invalid");
      document.querySelector("#class6").classList.add("is-valid");

      toastr.error("School must be Selected");

      document.querySelector("#class6address").classList.remove("is-valid");
      document.querySelector("#class6address").classList.add("is-invalid");
      document.querySelector("#class6address").focus();

      return;
    }

    props.setStep2(false);
    props.setStep3(true);

  };
  return (
    <div className="container form-xs">
      <div className="col-xs-12 col-sm-12 col-md-12 hidden-xs" style={{ marginBottom: 15 }}>
        <div>
          <h6 className="page-heading">Application Form for Scholarship/Admission in Class 7th in Centers of Excellence</h6>
          <p className="page-sub-heading">Under the scheme provision of Free &amp; Quality Education for Talented Students of Government Schools of Khyber Pakhtunkhwa</p>
        </div>
      </div>
      <div className="stepwizard hidden-sm hidden-md hidden-lg">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-4">
            <a href="#step-1" type="button"
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 1</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-2" onClick={handleSubmit} type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 2</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-3" type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 3</a>

          </div>
        </div>
      </div>



      <div className="stepwizard hidden-xs">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-1" type="button" onClick={() => { props.setStep2(false); props.setStep1(true) }}
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>1</a>
            <p><small>Personal Information</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-2" type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>2</a>
            <p><small>Name of Institution/School</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-3" onClick={handleSubmit} type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>3</a>
            <p><small>Desired Institution in order of Preference</small></p>
          </div>
        </div>
      </div>
      <form id="form3" onSubmit={handleSubmit}>
        <div className="z-depth-1 white col s12 l9 m8 offset-s0 offset-l2 offset-m3 form-container">

          <div className="panel setup-content" id="step-2">
            <h5 className="page-heading">Name of Institution/School</h5>
            <div className="panel-body">
              <div className="div-select-classes">
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class One:</label><span className="mandatory">*</span><label className="new-urdu">جماعت اول</label>
                  <select className="form-control" placehoder="Select Class 1" id="class1" name="class1"
                    value={props.applicant.class1}
                    onChange={onChange}>
                    {/* <option value>Select</option>
            <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions} {/* Render dynamic options based on gender */}

                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input id="class1address" name="class1address" value={props.applicant.class1address} onChange={onChange} maxLength={200} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class Two:</label><span className="mandatory">*</span><label className="new-urdu">جماعت دوم</label>
                  <select className="form-control" name="class2"
                    value={props.applicant.class2}
                    onChange={onChange} placehoder="Select Class 2" id="class2">
                    {/* <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input id="class2address" name="class2address" value={props.applicant.class2address} onChange={onChange} maxLength={200} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class Three:</label><span className="mandatory">*</span><label className="new-urdu">جماعت سوم</label>
                  <select className="form-control" name="class3"
                    value={props.applicant.class3}
                    onChange={onChange} placehoder="Select Class 3" id="class3">
                    {/* <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input maxLength={200} id="class3address" name="class3address" value={props.applicant.class3address} onChange={onChange} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class Four:</label><span className="mandatory">*</span><label className="new-urdu">جماعت چہارم</label>
                  <select className="form-control" name="class4"
                    value={props.applicant.class4}
                    onChange={onChange} placehoder="Select Class 4" id="class4">
                    {/* <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input maxLength={200} id="class4address" name="class4address" value={props.applicant.class4address} onChange={onChange} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class Five:</label><span className="mandatory">*</span><label className="new-urdu">جماعت پنجم</label>
                  <select className="form-control" name="class5"
                    value={props.applicant.class5}
                    onChange={onChange} placehoder="Select Class 5" id="class5">
                    {/* <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input id="class5address" name="class5address" value={props.applicant.class5address} onChange={onChange} maxLength={200} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Class Six:</label><span className="mandatory">*</span><label className="new-urdu">جماعت ششم</label>
                  <select className="form-control" name="class6"
                    value={props.applicant.class6}
                    onChange={onChange} placehoder="Select Class 6" id="class6">
                    {/* <option value="gps">Government Primary School</option>
            <option value="ggps">Government Girls Primary School</option>
            <option value="gcs">Government Community School</option> */}
                    {classOptions}

                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">School Address</label><span className="mandatory">*</span><label className="new-urdu">سکول کا پتہ</label>
                  <input id="class6address" name="class6address" value={props.applicant.class6address} onChange={onChange} maxLength={200} type="text" className="form-control" placeholder="Hint: Mohalla/Village Union Council City/District" />
                </div>
              </div>
              <div className="form-group col-xs-12 col-sm-12 padding-xs-0 text-align-center">
                <button className="btn btn-default prevBtn btn-style btn-large" type="button"
                  onClick={() => { props.setStep2(false); props.setStep1(true) }}
                >Previous</button>
                <input type="submit" value="Save & Next" name="Save & Next" className="btn btn-primary nextBtn btn-style btn-large" />

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Page2