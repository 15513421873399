import React, { useEffect, useState } from "react";
// import Page2 from "./Page2";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';


const Page1 = (props) => {
  const [showBox, setShowBox] = useState(false);
  useEffect(() => {
    toastr.options = {
      closeButton: false,
      timeOut: 5000,
      progressBar: true,
      positionClass: "toast-top-center",
      fontSize: '16px'
    };

    // toastr.error("Here is a warining meeesage");        // Now Validation

  }, []);

  // const [otherOccupation, setOtherOccupation] = useState("");

  // const handleOccupationChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setOccupation(selectedValue);

  //   // Clear "Other" input if switching away from "Other"
  //   if (selectedValue !== "Other") {
  //     setOtherOccupation("");
  //   }

  //   // Call the parent onChange function if necessary
  //   if (props.onChangeNew) {
  //     props.onChangeNew(e);
  //   }
  // };

  // const handleOtherOccupationChange = (e) => {
  //   setOtherOccupation(e.target.value);
  // };

  // const [applicant, setApplicant] = useState({});


  const onChange = (e) => {
    props.setApplicant({ ...props.applicant, [e.target.name]: e.target.value });
  };


  const onChangeNew = (e) => {
    let updatedApplicant = { ...props.applicant };
    updatedApplicant = {
      ...updatedApplicant,
      attempt: 'No',
      obtainedMarks: '0'
    };
    if (e.target.value === 'Yes') {

      setShowBox(true);
    } else {
      setShowBox(false);
    }

    updatedApplicant = {
      ...updatedApplicant,
      [e.target.name]: e.target.value
    };

    props.setApplicant(updatedApplicant);
  };

  // const FatherDetailsForm = (props) => {

  // };

  // const onChangeNew = (e) => {
  //   if (e.target.value === 'Yes') {
  //     props.setApplicant({...props.applicant,
  //       attempt: 'No',
  //       obtainedMarks: '0'});
  //       setShowBox(true);

  //   }


  //   else
  //   {
  //     setShowBox(false);
  //     const updatedApplicant = {
  //       ...props.applicant,
  //       attempt: 'No',
  //       obtainedMarks: '0'
  //     };
  //     props.setApplicant(updatedApplicant);
  //   }
  //   props.setApplicant({ ...props.applicant, [e.target.name]: e.target.value });
  //                };
  const showErrorMessage = (e, f) => {
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
    // const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    const start_date = new Date('2011-03-31');
    const end_date = new Date('2014-03-31');
    const applicantDate = new Date(props.applicant.birthday);

    if (props.applicant.name.length < 4) {

      toastr.error("Name cannot be less than 4 Characters");
      document.querySelector("#name").classList.remove("is-valid");
      document.querySelector("#name").classList.add("is-invalid");
      document.querySelector("#name").focus();

      return;
    }
    else if (props.applicant.fname.length < 4) {
      document.querySelector("#name").classList.remove("is-invalid");
      document.querySelector("#name").classList.add("is-valid");

      toastr.error("Father Name cannot be less than 4 Characters");

      document.querySelector("#fname").classList.remove("is-valid");
      document.querySelector("#fname").classList.add("is-invalid");
      document.querySelector("#fname").focus();

      return;
    }

    else if (props.applicant.fatherStatus.length < 3) {
      // document.querySelector("#fname").classList.remove("is-invalid");
      // document.querySelector("#fname").classList.add("is-valid");

      toastr.error("Father Status must be selected");

      document.querySelector("#fatherStatus").classList.remove("is-valid");
      document.querySelector("#fatherStatus").classList.add("is-invalid");
      document.querySelector("#fatherStatus").focus();

      return;
    }

    else if (props.applicant.fatherOccupation.length < 3) {
      // document.querySelector("#fname").classList.remove("is-invalid");
      // document.querySelector("#fname").classList.add("is-valid");

      toastr.error("Father Occupation must be selected");

      document.querySelector("#fatherOccupation").classList.remove("is-valid");
      document.querySelector("#fatherOccupation").classList.add("is-invalid");
      document.querySelector("#fatherOccupation").focus();

      return;
    }

    // else if (selectedValue === "Other") {
    // if (otherOccupation.length < 3) {
    //   // document.querySelector("#fname").classList.remove("is-invalid");
    //   // document.querySelector("#fname").classList.add("is-valid");

    //   toastr.error("Other Occupation must be selected");

    //   // document.querySelector("#gender").classList.remove("is-valid");
    //   // document.querySelector("#gender").classList.add("is-invalid");
    //   // document.querySelector("#Occupation").focus();

    //   return;
    // }
    // }


    else if (props.applicant.gender.length < 3) {
      document.querySelector("#fname").classList.remove("is-invalid");
      document.querySelector("#fname").classList.add("is-valid");

      toastr.error("Gender must be selected");

      document.querySelector("#gender").classList.remove("is-valid");
      document.querySelector("#gender").classList.add("is-invalid");
      document.querySelector("#gender").focus();

      return;
    }

    else if (props.applicant.birthday.length < 10) {
      document.querySelector("#gender").classList.remove("is-invalid");
      document.querySelector("#gender").classList.add("is-valid");
      toastr.error("Date of Birth Field Cannot be Empty");

      document.querySelector("#birthday").classList.remove("is-valid");
      document.querySelector("#birthday").classList.add("is-invalid");
      document.querySelector("#birthday").focus();


      return;
    } else if (!dateRegex.test(props.applicant.birthday)) {
      document.querySelector("#gender").classList.remove("is-invalid");
      document.querySelector("#gender").classList.add("is-valid");

      toastr.error("Date of Birth Must be in the format MM/DD/YYYY");

      document.querySelector("#birthday").classList.remove("is-valid");
      document.querySelector("#birthday").classList.add("is-invalid");
      document.querySelector("#birthday").focus();


      return;
    }
    if (!(applicantDate >= start_date && applicantDate <= end_date)) {
      // Handle invalid date range here
      toastr.error("Date of Birth must be between 31/03/2011 and 31/03/2014");
      // Add classes, focus, or other handling for invalid date range
      document.querySelector("#birthday").classList.remove("is-valid");
      document.querySelector("#birthday").classList.add("is-invalid");
      document.querySelector("#birthday").focus();
      return;
    }

    else if (props.applicant.cnic.length !== 13 || isNaN(props.applicant.cnic)) {
      document.querySelector("#birthday").classList.remove("is-invalid");
      document.querySelector("#birthday").classList.add("is-valid");
      toastr.error("CNIC must be of 13 digits (without dashes)");

      document.querySelector("#cnic").classList.remove("is-valid");
      document.querySelector("#cnic").classList.add("is-invalid");
      document.querySelector("#cnic").focus();


      return;
    }
    else if (props.applicant.fcnic.length !== 13 || isNaN(props.applicant.fcnic)) {
      document.querySelector("#cnic").classList.remove("is-invalid");
      document.querySelector("#cnic").classList.add("is-valid");
      toastr.error("Father CNIC must be of 13 digits (without dashes)");

      document.querySelector("#fcnic").classList.remove("is-valid");
      document.querySelector("#fcnic").classList.add("is-invalid");
      document.querySelector("#fcnic").focus();


      return;
    }
    else if (props.applicant.cnic === props.applicant.fcnic) {
      document.querySelector("#fcnic").classList.remove("is-invalid");
      document.querySelector("#fcnic").classList.add("is-valid");
      toastr.error("Student CNIC/ Form-B cannot be same as Father CNIC");

      document.querySelector("#cnic").classList.remove("is-valid");
      document.querySelector("#cnic").classList.add("is-invalid");
      document.querySelector("#cnic").focus();


      return;
    }
    else if (props.applicant.contact1.length < 11 || isNaN(props.applicant.contact1)) {
      document.querySelector("#fcnic").classList.remove("is-invalid");
      document.querySelector("#fcnic").classList.add("is-valid");
      toastr.error("Enter 11-digit Valid Mobile Number ");

      document.querySelector("#contact1").classList.remove("is-valid");
      document.querySelector("#contact1").classList.add("is-invalid");
      document.querySelector("#contact1").focus();


      return;
    }
    else if (props.applicant.contact2.length < 11 || isNaN(props.applicant.contact1)) {
      document.querySelector("#contact1").classList.remove("is-invalid");
      document.querySelector("#contact1").classList.add("is-valid");
      toastr.error("Enter 11-digit Valid Alternate Mobile Number ");

      document.querySelector("#contact2").classList.remove("is-valid");
      document.querySelector("#contact2").classList.add("is-invalid");
      document.querySelector("#contact2").focus();


      return;
    }
    else if (props.applicant.domicile.length < 1) {
      document.querySelector("#contact2").classList.remove("is-invalid");
      document.querySelector("#contact2").classList.add("is-valid");
      toastr.error("Please select your domicile");

      document.querySelector("#domicile").classList.remove("is-valid");
      document.querySelector("#domicile").classList.add("is-invalid");
      document.querySelector("#domicile").focus();


      return;
    }

    else if (props.applicant.religion.length < 1) {
      document.querySelector("#domicile").classList.remove("is-invalid");
      document.querySelector("#domicile").classList.add("is-valid");
      toastr.error("Religion must be selected");

      document.querySelector("#religion").classList.remove("is-valid");
      document.querySelector("#religion").classList.add("is-invalid");
      document.querySelector("#religion").focus();


      return;
    }
    else if (props.applicant.attemptStatus.length < 2) {
      document.querySelector("#religion").classList.remove("is-invalid");
      document.querySelector("#religion").classList.add("is-valid");
      toastr.error("Already Appeared Status must be provided");

      document.querySelector("#attemptStatus").classList.remove("is-valid");
      document.querySelector("#attemptStatus").classList.add("is-invalid");
      document.querySelector("#attemptStatus").focus();


      return;
    }
    else if (props.applicant.attemptStatus === 'Yes' && props.applicant.attempt.length < 3) {
      document.querySelector("#attemptStatus").classList.remove("is-invalid");
      document.querySelector("#attemptStatus").classList.add("is-valid");
      toastr.error("Number of Attempts must be provided");

      document.querySelector("#attempt").classList.remove("is-valid");
      document.querySelector("#attempt").classList.add("is-invalid");
      document.querySelector("#attempt").focus();


      return;
    }
    else if (props.applicant.attemptStatus === 'Yes' && (props.applicant.obtainedMarks.length < 1 || isNaN(props.applicant.obtainedMarks))) {
      document.querySelector("#attempt").classList.remove("is-invalid");
      document.querySelector("#attempt").classList.add("is-valid");
      toastr.error("Obtained Marks must be provided");

      document.querySelector("#obtainedMarks").classList.remove("is-valid");
      document.querySelector("#obtainedMarks").classList.add("is-invalid");
      document.querySelector("#obtainedMarks").focus();


      return;
    }
    else if (props.applicant.currentClass.length < 1) {
      document.querySelector("#obtainedMarks").classList.remove("is-invalid");
      document.querySelector("#obtainedMarks").classList.add("is-valid");
      toastr.error("Current Class must be Selected");

      document.querySelector("#currentClass").classList.remove("is-valid");
      document.querySelector("#currentClass").classList.add("is-invalid");
      document.querySelector("#currentClass").focus();


      return;
    }
    else if (props.applicant.testCity.length < 1) {
      document.querySelector("#currentClass").classList.remove("is-invalid");
      document.querySelector("#currentClass").classList.add("is-valid");
      toastr.error("Test City must be Selected");

      document.querySelector("#testCity").classList.remove("is-valid");
      document.querySelector("#testCity").classList.add("is-invalid");
      document.querySelector("#testCity").focus();


      return;
    }
    else if (props.applicant.presentAddress.length < 3) {
      document.querySelector("#testCity").classList.remove("is-invalid");
      document.querySelector("#testCity").classList.add("is-valid");
      toastr.error("Please enter complete Present Address");

      document.querySelector("#presentAddress").classList.remove("is-valid");
      document.querySelector("#presentAddress").classList.add("is-invalid");
      document.querySelector("#presentAddress").focus();


      return;
    }
    else if (props.applicant.permanentAddress.length < 3) {
      document.querySelector("#presentAddress").classList.remove("is-invalid");
      document.querySelector("#presentAddress").classList.add("is-valid");
      toastr.error("Please enter complete Permanent Address");

      document.querySelector("#permanentAddress").classList.remove("is-valid");
      document.querySelector("#permanentAddress").classList.add("is-invalid");
      document.querySelector("#permanentAddress").focus();


      return;
    }
    else if (props.applicant.hostel.length < 1) {
      document.querySelector("#permanentAddress").classList.remove("is-invalid");
      document.querySelector("#permanentAddress").classList.add("is-valid");
      toastr.error("Hostel Status must be provided");

      document.querySelector("#hostel").classList.remove("is-valid");
      document.querySelector("#hostel").classList.add("is-invalid");
      document.querySelector("#hostel").focus();


      return;
    }



    props.setStep1(false);
    props.setStep2(true);

  };
  return (
    <div className="container form-xs">
      <div className="col-xs-12 col-sm-12 col-md-12  hidden-xs" style={{ marginBottom: 15 }}>
        <div>
          <h6 className="page-heading">Application Form for Scholarship/Admission in Class 7th in Centers of Excellence</h6>
          <p className="page-sub-heading">Under the scheme provision of Free &amp; Quality Education for Talented Students of Government Schools of Khyber Pakhtunkhwa</p>
        </div>
      </div>
      <div className="stepwizard hidden-sm hidden-md hidden-lg">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-4">
            <a href="#step-1" type="button"
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 1</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-2" onClick={handleSubmit} type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 2</a>

          </div>
          <div className="stepwizard-step col-xs-4">
            <a href="#step-3" type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>Step 3</a>

          </div>
        </div>
      </div>



      <div className="stepwizard hidden-xs">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-1" type="button"
              className={props.step1 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>1</a>
            <p><small>Personal Information</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-2" onClick={handleSubmit} type="button" className={props.step2 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>2</a>
            <p><small>Name of Institution/School</small></p>
          </div>
          <div className="stepwizard-step col-xs-12 col-sm-12 col-md-4">
            <a href="#step-3" type="button" className={props.step3 === true ? "btn btn-success btn-circle" : "btn btn-default btn-circle"}>3</a>
            <p><small>Desired Institution in order of Preference</small></p>
          </div>
        </div>
      </div>


      <form id="form1" onSubmit={handleSubmit}>



        {/* Step 1 Data to be shown here... */}

        <div className="z-depth-1 white col s12 l9 m8 offset-s0 offset-l2 offset-m3 form-container">
          <div className="panel setup-content" id="step-1">
            <h5 className="page-heading">Personal Information
            </h5>
            <div className="panel-body">
              <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Name of Student:</label><span className="mandatory">*</span><label className="new-urdu">طالب علم کا نام</label>

                <input
                  maxLength={200}
                  type="text"
                  placeholder="Name of Student"
                  className="form-control input-label-style"
                  id="name"
                  name="name"
                  value={props.applicant.name}
                  onChange={onChange}


                />
              </div>
              <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Father Name:</label><span className="mandatory">*</span><label className="new-urdu">والد کا نام</label>
                <input
                  maxLength={100}
                  type="text"
                  placeholder="Father Name"

                  className="form-control input-label-style"
                  id="fname"
                  name="fname"
                  value={props.applicant.fname}
                  onChange={onChange}
                />
              </div>

              <div>
                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Father's Status:</label><span className="mandatory">*</span><label className="new-urdu">والد کی حیثیت</label>
                  <select
                    className="form-control"

                    id="fatherStatus"
                    name="fatherStatus"
                    value={props.applicant.fatherStatus}
                    onChange={onChange}
                  >
                    <option value="">Select Father's Status</option>
                    <option value="Alive">Alive</option>
                    <option value="Deceased">Deceased</option>
                  </select>
                </div>

                <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Father's Occupation</label>
                  <span className="mandatory">*</span>
                  <label className="new-urdu">والد کا پیشہ</label>
                  <select
                    className="form-control"
                    id="fatherOccupation"
                    name="fatherOccupation"
                    value={props.applicant.fatherOccupation}
                    onChange={onChange}
                  >
                    <option value="">Select One</option>
                    <option value="GovernmentEmployee">Government Employee</option>
                    <option value="PrivateEmployees">Private Employee</option>
                    <option value="Businessman">Businessman</option>
                    <option value="Farmer">Farmer</option>
                    <option value="Laborer">Laborer</option>
                    <option value="Retired">Retired</option>
                    <option value="Shopkeeper">Shopkeeper</option>

                    <option value="Other">Other (please specify)</option>
                  </select>
                </div>

                {/* Conditionally render "Other" input field */}
                {props.applicant.fatherOccupation === "Other" && (
                  <div className="form-group col-xs-12 col-sm-12 padding-xs-0">
                    <label className="control-label label-english">Specify Other Occupation</label>
                    <span className="mandatory">*</span>
                    <label className="new-urdu">دیگر پیشہ بیان کریں</label>
                    <input
                      maxLength={100}
                      type="text"
                      id="fatherOccupationOther"
                      name="fatherOccupationOther"
                      placeholder="Specify Other Occupation"
                      className="form-control input-label-style"
                      value={props.applicant.fatherOccupationOther}
                      onChange={onChange}
                    />
                  </div>
                )}


              </div>

              {/* <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Father's Status:</label><span className="mandatory">*</span><label className="new-urdu">والد کی حیثیت</label>
                <select
                  className="form-control"

                  id="status"
                  name="status"
                  value={props.applicant.status}
                  onChange={onChange}
                >
                  <option value="">Select Father's Status</option>
                  <option value="Alive">Alive</option>
                  <option value="Deceased">Deceased</option>
                </select>
              </div>

              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Father's Occupation  </label><span className="mandatory">*</span><label className="new-urdu">والد کا پیشہ</label>
                <select
                  className="form-control"

                  placehoder="Father's Occupation"
                  id="Occupation"
                  name="Occupation"
                  value={props.applicant.Occupation}
                  onChange={onChangeNew}
                >
                  <option value="">Select One</option>
                  <option value={'GovernmentEmployee'}>Government Employee</option>
                  <option value={'PrivateEmployees'}>Private Employee</option>
                  <option value={'Businessman'}>Businessman</option>

                  <option value={'Farmer'}>Farmer</option>

                  <option value={'Laborer'}>Laborer</option>

                  <option value={'Retired'}>Retired</option>

                  <option value={'Other'}>Other (please specify)</option>


                </select>
              </div>
              {showBox && <>
                <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Previous Attempt:</label><span className="mandatory">*</span><label className="new-urdu">    امتحان میں کوششوں کی تعداد</label>
                  <select
                    className="form-control"

                    placehoder="Previous Attempt"
                    id="attempt"
                    name="attempt"
                    value={props.applicant.attempt}
                    onChange={onChange}
                  >
                    <option value="">Select One</option>
                    <option value={'One'}>One</option>
                    <option value={'Two'}>Two</option>
                    <option value={'Three'}>Three</option>
                    <option value={'Four'}>Four</option>
                  </select>
                </div>
                <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Obtained Marks:</label><span className="mandatory">*</span><label className="new-urdu">حاصل کردہ نمبر</label>
                  <input
                    maxLength={50}
                    type="text"

                    placeholder="Obtained Marks"
                    className="form-control input-label-style"
                    id="obtainedMarks"
                    name="obtainedMarks"
                    value={props.applicant.obtainedMarks}
                    onChange={onChange}
                  />
                </div></>} */}


              <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Gender:</label><span className="mandatory">*</span><label className="new-urdu">جنس</label>
                <select
                  className="form-control"

                  id="gender"
                  name="gender"
                  value={props.applicant.gender}
                  onChange={onChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Boy">Boy</option>
                  <option value="Girl">Girl</option>
                </select>
              </div>

              <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Date of Birth(31-03-2011 to 31-03-2014):</label>
                <span className="mandatory">*</span>
                <label className="new-urdu">تاریخ پیدائش</label>
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  className="form-control input-label-style"
                  value={props.applicant.birthday}
                  onChange={onChange}
                  min="2011-03-31" // Set the minimum date to 1st January 2012
                  max="2014-03-31" // Set the maximum date to 31st December 2013
                />
              </div>


              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Form-B/CNIC:</label><span className="mandatory">*</span><label className="new-urdu">فارم ب/ شناختی کارڈ نمبر</label>
                <input
                  maxLength={13}
                  type="text"
                  placeholder="6110112345678 (without dashes)"

                  className="form-control input-label-style"
                  id="cnic"
                  name="cnic"
                  value={props.applicant.cnic}
                  onChange={onChange} />
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Father/Guardian CNIC:</label><span className="mandatory">*</span><label className="new-urdu">والد/سرپرست شناختی کارڈ نمبر</label>
                <input
                  maxLength={13}
                  type="text"
                  placeholder="111111234567 (without dashes)"

                  className="form-control input-label-style"
                  id="fcnic"
                  name="fcnic"
                  value={props.applicant.fcnic}
                  onChange={onChange}
                />
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Mobile No:</label><span className="mandatory">*</span><label className="new-urdu">موبائل نمبر</label>
                <input
                  maxLength={11}
                  type="text"

                  placeholder={'03001234567'}
                  className="form-control input-label-style"
                  id="contact1"
                  name="contact1"
                  value={props.applicant.contact1}
                  onChange={onChange} />
              </div>
              <div className="form-group col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Alternate No:</label><span className="mandatory">*</span><label className="new-urdu">متبادل نمبر</label>
                <input
                  maxLength={11}
                  type="text"
                  placeholder={'03001234567'}


                  className="form-control input-label-style"
                  id="contact2"
                  name="contact2"
                  value={props.applicant.contact2}
                  onChange={onChange} />
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Domicile:</label><span className="mandatory">*</span><label className="new-urdu">ڈومیسائل</label>
                <select
                  className="form-control"

                  placehoder="Select Domicile"
                  id="domicile"
                  name="domicile"
                  value={props.applicant.domicile}
                  onChange={onChange}
                >
                  <option value="">Select One</option>
                  <option value={'Abbottabad'}>Abbottabad</option>
                  <option value={'Bannu'}>Bannu</option>
                  <option value={'Battagram'}>Battagram</option>
                  <option value={'Buner'}>Buner</option>
                  <option value={'Charsadda'}>Charsadda</option>
                  <option value={'Chitral (Lower)'}>Chitral (Lower)</option>
                  <option value={'Chitral (Upper)'}>Chitral (Upper)</option>
                  <option value={'D.I.Khan'}>D.I.Khan</option>
                  <option value={'Dir (Lower)'}>Dir (Lower)</option>
                  <option value={'Dir (Upper)'}>Dir (Upper)</option>
                  <option value={'Hangu'}>Hangu</option>
                  <option value={'Haripur'}>Haripur</option>
                  <option value={'Karak'}>Karak</option>
                  <option value={'Kohat'}>Kohat</option>
                  <option value={'Kohistan'}>Kohistan</option>
                  <option value={'Kolai Pallas'}>Kolai Pallas</option>
                  <option value={'Lakki Marwat'}>Lakki Marwat</option>
                  <option value={'Malakand'}>Malakand</option>
                  <option value={'Mansehra'}>Mansehra</option>
                  <option value={'Mardan'}>Mardan</option>
                  <option value={'Nowshera'}>Nowshera</option>
                  <option value={'Peshawar'}>Peshawar</option>
                  <option value={'Shangla'}>Shangla</option>
                  <option value={'Swabi'}>Swabi</option>
                  <option value={'Swat'}>Swat</option>
                  <option value={'Tank'}>Tank</option>
                  <option value={'TorGhar'}>TorGhar</option>
                  <option value={'Bajaur'}>Bajaur</option>
                  <option value={'BHETTANI LAKKI (FR-LAKKI)'}>Bhettani Lakki (FR-Lakki)</option>
                  <option value={'DARAZINDA-DIKHAN (FR-DIKHAN)'}>Darazinda-D.I.Khan (D.I.Khan)</option>
                  <option value={'DARRA KOHAT (FR-KOHAT)'}>Darra Kohat (FR-Koat)</option>
                  <option value={'HASSAN KHEL PESHAWAR (FR-PESHAWAR)'}>Hassan Khel Peshawar (FR-Peshawar)</option>
                  <option value={'JANDOLA (FR-TANK)'}>Jandola (FR-Tank)</option>
                  <option value={'KHYBER'}>Khyber</option>
                  <option value={'KURRAM'}>Kurram</option>
                  <option value={'MOHMAND'}>Mohmand</option>
                  <option value={'NORTH WAZIRISTAN'}>North Waziristan</option>
                  <option value={'ORAKZAI'}>Orakzai</option>
                  <option value={'SOUTH WAZIRISTAN'}>South Waziristan</option>
                  <option value={'WAZIR BANNU (FR-BANNU)'}>Wazir Bannu (FR-Bannu)</option>
                </select>
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Religion:</label><span className="mandatory">*</span><label className="new-urdu">مذہب</label>
                <select
                  className="form-control"

                  placehoder="Select Religion"
                  id="religion"
                  name="religion"
                  value={props.applicant.religion}
                  onChange={onChange}
                >
                  <option value>Select One</option>
                  <option value={'Muslim'}>Muslim</option>
                  <option value={'Non-Muslim'}>Non-Muslim</option>
                </select>
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Current Class:</label><span className="mandatory">*</span><label className="new-urdu">موجودہ کلاس</label>
                <select
                  className="form-control"

                  placehoder="Select Class in which studying"
                  id="currentClass"
                  name="currentClass"
                  value={props.applicant.currentClass}
                  onChange={onChange}
                >
                  <option value>Select One</option>
                  <option value={'6th'}>6th</option>
                  <option value={'7th'}>7th</option>
                </select>
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Already appeared?</label><span className="mandatory">*</span><label className="new-urdu">کیا آپ نے پہلے ٹیسٹ دیا ہے؟</label>
                <select
                  className="form-control"

                  placehoder="Attempt Status"
                  id="attemptStatus"
                  name="attemptStatus"
                  value={props.applicant.attemptStatus}
                  onChange={onChangeNew}
                >
                  <option value="">Select One</option>
                  <option value={'No'}>No</option>
                  <option value={'Yes'}>Yes</option>
                </select>
              </div>
              {showBox && <>
                <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Previous Attempt:</label><span className="mandatory">*</span><label className="new-urdu">    امتحان میں کوششوں کی تعداد</label>
                  <select
                    className="form-control"

                    placehoder="Previous Attempt"
                    id="attempt"
                    name="attempt"
                    value={props.applicant.attempt}
                    onChange={onChange}
                  >
                    <option value="">Select One</option>
                    <option value={'One'}>One</option>
                    <option value={'Two'}>Two</option>
                    <option value={'Three'}>Three</option>
                    <option value={'Four'}>Four</option>
                  </select>
                </div>
                <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                  <label className="control-label label-english">Obtained Marks:</label><span className="mandatory">*</span><label className="new-urdu">حاصل کردہ نمبر</label>
                  <input
                    maxLength={50}
                    type="text"

                    placeholder="Obtained Marks"
                    className="form-control input-label-style"
                    id="obtainedMarks"
                    name="obtainedMarks"
                    value={props.applicant.obtainedMarks}
                    onChange={onChange}
                  />
                </div></>}



              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Desired Test City:</label><span className="mandatory">*</span><label className="new-urdu">مطلوبہ ٹیسٹ شہر</label>
                <select
                  className="form-control"

                  placehoder="Desired Test City"
                  id="testCity"
                  name="testCity"
                  value={props.applicant.testCity}
                  onChange={onChange}
                >
                  <option value>Select One</option>
                  <option value={'Abbottabad'}>Abbottabad</option>
                  <option value={'Mansehra'}>Mansehra</option>
                  <option value={'Swat'}>Swat</option>
                  <option value={'Malakand/Chakdara'}>Malakand/Chakdara</option>
                  <option value={'Chitral'}>Chitral</option>
                  <option value={'Mardan'}>Mardan</option>
                  <option value={'Peshawar'}>Peshawar</option>
                  <option value={'Kohat'}>Kohat</option>
                  <option value={'Bannu'}>Bannu</option>
                  <option value={'D.I.Khan'}>D.I.Khan</option>


                </select>
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Present Address:</label><span className="mandatory">*</span><label className="new-urdu">موجودہ پتہ</label>
                <textarea

                  type="text"
                  rows={3}

                  className="form-control"
                  placeholder="Enter Present Address"
                  id="presentAddress"
                  name="presentAddress"
                  value={props.applicant.presentAddress}
                  onChange={onChange}
                />
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Permanent Address:</label><span className="mandatory">*</span><label className="new-urdu">مستقل پتہ</label>
                <textarea

                  type="text" rows={3}

                  className="form-control"
                  placeholder="Enter Permanent Address"
                  id="permanentAddress"
                  name="permanentAddress"
                  value={props.applicant.permanentAddress}
                  onChange={onChange}
                />
              </div>
              <div className="form-group  col-xs-12 col-sm-6 padding-xs-0">
                <label className="control-label label-english">Hostel:</label><span className="mandatory">*</span><label className="new-urdu">ہاسٹل</label>
                <select
                  className="form-control"

                  id="hostel"
                  name="hostel"
                  value={props.applicant.hostel}
                  onChange={onChange}
                >
                  <option value="">Need Hostel?</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="col-xs-12 text-align-center" style={{ marginTop: 10 }}>
                <input
                  type="submit"
                  value="Save & Next"
                  className="btn btn-primary btn-large"
                />


              </div>
            </div>
          </div>



        </div>

      </form>
    </div>

  )
}

export default Page1